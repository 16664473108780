import { createSlice } from "@reduxjs/toolkit";

export const agentSlice = createSlice({
  name: "agent",
  initialState: {
    selectedAgent: null,
    selectedAgentWorkflow: null,
    agents: [],
    agentTasks: [],
    agentCallTask: null,
    agentOverviewReport: {},
    workflow: {},
    agentCurrentWeekTimeSaved: {},
    agentSetupForm: {
      name: "",
      description: "",
      agent_system_prompt: "",
      agent_status: "",
      knowledge_base_integration_config_ids: "",
      action_and_tools_config_ids: "",
      tool_slack_constraints_json: "",
      tool_mail_constraints_json: "",
      deployed_phone_number: "",
      deployed_email: "",
      predefined_models_id: 0,
      workflow_id: null,
      client_id: null,
    },
  },
  reducers: {
    setAgent: (state, action) => {
      state.selectedAgent = action.payload;
    },
    setAgentWorkflow: (state, action) => {
      state.selectedAgentWorkflow = action.payload;
    },
    setAgents: (state, action) => {
      state.agents = action.payload;
    },
    setAgentTasks: (state, action) => {
      state.agentTasks = action.payload;
    },
    setAgentCallTask: (state, action) => {
      state.agentCallTask = action.payload;
    },
    setAgentOverviewReport: (state, action) => {
      state.agentOverviewReport = action.payload;
    },
    setAgentCurrentWeekSavedTime: (state, action) => {
      state.agentCurrentWeekTimeSaved = action.payload;
    },
    setAgentSetupForm: (state, action) => {
      state.agentSetupForm = action.payload;
    },
  },
});

export const {
  setAgent,
  setAgentWorkflow,
  setAgents,
  setAgentTasks,
  setAgentCallTask,
  setAgentOverviewReport,
  setAgentCurrentWeekSavedTime,
  setAgentSetupForm,
} = agentSlice.actions;

export const selectAgent = (state) => state.agent.selectedAgent;
export const selectAgentWorkflow = (state) => state.agent.selectedAgentWorkflow;
export const getAgents = (state) => state.agent.agents;
export const getAgentTasks = (state) => state.agent.agentTasks;
export const getAgentCallTask = (state) => state.agent.agentCallTask;
export const getAgentOverviewReport = (state) =>
  state.agent.agentOverviewReport;
export const getAgentWeeklyTimeSaveReport = (state) =>
  state.agent.agentCurrentWeekTimeSaved;
export const getAgentSetupForm = (state) => {
  return state.agent.agentSetupForm;
};
export default agentSlice.reducer;
