import { createSlice } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    clients: [],
    selectedClient: {},
  },
  reducers: {
    setAllClients: (state, action) => {
      state.clients = action.payload;
    },
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
  },
});

export const { setAllClients, setSelectedClient } = clientSlice.actions;

export const getClients = (state) => state.client.clients;
export const getSelectClient = (state) => state.client.selectedClient;

export default clientSlice.reducer;
