import React, { useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import ConfirmationModal from "./ConfirmationModal";
import DeployModal from "./DeployModal";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgentSetupForm,
  getWorkflow,
  setAgentSetupForm,
  setUserNumber,
} from "../../redux";
import { toast } from "react-toastify";

export const Step6 = ({ prevStep, handleSubmit }) => {
  const workFlows = useSelector(getWorkflow);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const agentSetupForm = useSelector(getAgentSetupForm);
  const [toggle, setToggle] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();
  const handleDeploy = () => {
    const bt = document.getElementById("phone");
    bt.classList.add("animate-bounce");
    if (!agentSetupForm?.deployed_phone_number) {
      toast.warning("Select Phone Number First");
    } else {
      setShowConfirmationModal(true);
    }
  };

  const confirmDeploymentAndOpenDeployModal = () => {
    setShowConfirmationModal(false);
    setIsModalOpen(true);
    setTimeout(() => {
      handleSubmit();
    }, 5000);
  };
  const setMobile = (e) => {
    const value = e.target.value;
    dispatch(
      setAgentSetupForm({
        ...agentSetupForm,
        deployed_phone_number: value,
      })
    );
    dispatch(setUserNumber(value));
  };
  return (
    <div className="w-full bg-[#212121] px-16 py-6 flex flex-col gap-6 rounded-lg">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-4">
            <div className="w-6 h-6 mt-1 rounded-md bg-white text-center text-black">
              6
            </div>
            <p className="text-[32px] font-semibold text-white">
              Time to deploy your agent?
            </p>
          </div>
          <div className="pl-10">
            <span className="text-[16px] text-[#BDBDBD]">
              Where would you like to deploy your agent?
            </span>
          </div>
        </div>
        <div className="w-full bg-[#101010] h-[1px]"></div>

        {/* Buttons for selecting deployment type */}
        <div className="flex flex-col mt-6 gap-4">
          <div className="flex gap-4">
            <button
              className={`bg-[#1A1B20] border-[1.81px]  ${
                workFlows?.deployable_on_phone
                  ? "border-[#007AFF]"
                  : "border-[#2D2D2D]"
              }  text-white w-36 py-4 px-2 rounded`}
              disabled={!workFlows?.deployable_on_phone}
              onClick={() => {
                setSelectedOption("Phone");
                setToggle(!toggle);
                const bt = document.getElementById("phone");
                bt.classList.remove("animate-bounce");
              }}
              id="phone"
            >
              Phone
            </button>
            <button
              className={`bg-[#1A1B20] border-[1.81px] ${
                workFlows?.deployable_on_web
                  ? "border-[#007AFF]"
                  : "border-[#2D2D2D]"
              } text-white w-36 py-4 px-2 rounded  `}
              disabled={!workFlows?.deployable_on_web}
            >
              Website URL
            </button>
            <button
              className={`bg-[#1A1B20] border-[1.81px] border-[#2D2D2D] text-white w-36 py-4 px-2 rounded opacity-50 cursor-not-allowed`}
              disabled
            >
              Email
            </button>
            <button
              className={`bg-[#1A1B20] border-[1.81px] border-[#2D2D2D] ${workFlows} text-white w-36 py-4 px-2 rounded opacity-50 cursor-not-allowed`}
              disabled
            >
              Chrome Extension
            </button>
          </div>
        </div>
        {selectedOption === "Phone" && toggle && (
          <div className="flex flex-col justify-start items-start space-y-4">
            <label htmlFor="" className="font-bold text-white">
              Phone
            </label>
            <select
              name="phone"
              className="w-xl max-w-2xl p-2 bg-[#1A1B20] text-white roundedl"
              onChange={(e) => setMobile(e)}
            >
              <option selected disabled className="w-full">
                Select your phone number
              </option>
              <option
                value={process.env.REACT_APP_BASE_NUMBER}
                className="w-full"
              >
                {process.env.REACT_APP_BASE_NUMBER}
              </option>
            </select>
          </div>
        )}
      </div>

      <div className="flex justify-end gap-6">
        <button
          onClick={prevStep}
          className="text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline"
        >
          <FaArrowLeftLong size={30} />
        </button>
        <button
          // disabled={
          //   agentSetupForm?.deployed_phone_number === "" ||
          //   !agentSetupForm?.deployed_phone_number
          // }
          onClick={handleDeploy}
          className={`w-[25%] px-4 py-2 text-white rounded-md ${
            !agentSetupForm?.deployed_phone_number
              ? "bg-gray-400"
              : "bg-[#007AFF] "
          }  hover:bg-blue-600 focus:outline-none`}
        >
          Deploy
        </button>
      </div>

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={confirmDeploymentAndOpenDeployModal} // Call the function that triggers the DeployModal
          deploymentType="Phone"
          deploymentConnection={process.env.REACT_APP_BASE_NUMBER}
        />
      )}

      {isModalOpen && (
        <DeployModal
          onClose={closeModal}
          agentName={"Support"}
          agentDescription={agentSetupForm.description}
          prompt={agentSetupForm.agent_system_prompt}
          deploymentConnection={process.env.REACT_APP_BASE_NUMBER}
        />
      )}
    </div>
  );
};
