import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCallLogs, setCallLogs } from "../../redux";
import { getAllAuditLogs } from "../../api/auditLog";
import { FaEye } from "react-icons/fa6";
import DetailModal from "./DetailModal";
export const CallAuditTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState({});
  const [sortKey, setSortKey] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const allCallLogs = useSelector(getCallLogs);
  const dispatch = useDispatch();
  const handleSearch = (event) => setSearchTerm(event.target.value);
  const handleSort = (key) => {
    const order = sortKey === key && sortOrder === "asc" ? "desc" : "asc";
    setSortKey(key);
    setSortOrder(order);
  };

  const filteredCallLogs = allCallLogs
    ?.filter((log) =>
      [log.message, log.job_name].some((field) =>
        field?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    .sort((a, b) => {
      if (a[sortKey] < b[sortKey]) return sortOrder === "asc" ? -1 : 1;
      if (a[sortKey] > b[sortKey]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

  useEffect(() => {
    const res = getAllAuditLogs();
    res.then((data) => {
      dispatch(setCallLogs(data));
    });
  }, []);

  return (
    <div className="w-full relative pr-8">
      <div className="flex justify-between items-center">
        <h2 className="text-black dark:text-white font-extrabold text-start mb-4 mt-4">
          Call Logs
        </h2>
      </div>
      <div className="relative shadow-md sm:rounded-lg bg-gray-900 mt-6">
        <div className="flex flex-col sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
          <div className="relative">
            <input
              type="text"
              id="table-search"
              className="outline-none p-2 pl-10 text-sm border rounded-lg w-80 bg-gray-700 border-gray-600 dark:placeholder-gray-400 text-white"
              placeholder="Search for Logs"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-400 p-8">
          <thead className="text-xs uppercase bg-gray-700 text-gray-400">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("name")}
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("id")}
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 cursor-pointer"
                onClick={() => handleSort("deployed_email")}
              >
                Message
              </th>

              <th scope="col" className="px-6 py-3 cursor-pointer text-center">
                Creation Date
              </th>
              <th scope="col" className="px-6 py-3 cursor-pointer text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredCallLogs?.length > 0 ? (
              filteredCallLogs?.map((call) => (
                <tr
                  key={call.id}
                  className="border-b bg-gray-800 border-gray-700 hover:bg-gray-900"
                >
                  <td className="px-6 py-4">{call.job_name}</td>
                  <td
                    className="px-6 py-4 font-medium whitespace-nowrap text-white"
                    style={{
                      color: `${call.status === "Failed" ? "red" : "green"}`,
                    }}
                  >
                    {call.status}
                  </td>
                  <td className="px-6 py-4">{call.message}</td>
                  <td className="px-6 py-4">{call.created_at}</td>

                  <td className="px-6 py-4 flex justify-center items-center gap-4 cursor-pointer hover:text-green-400 ">
                    <p
                      className="border inline px-3 py-1 border-gray-500 hover:animate-pulse"
                      onClick={() => {
                        setSelectedLog(call?.message);
                        setIsModalOpen(true);
                      }}
                    >
                      <FaEye />
                    </p>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No Logs found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <DetailModal
        data={selectedLog}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
