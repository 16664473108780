import React, { useEffect, useState } from "react";
import { Step1 } from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { Step4 } from "./Step4";
import { FaCheck } from "react-icons/fa6";
import { Step6 } from "./Step6";
import { Step5 } from "./Step5";
import { useNavigate } from "react-router-dom";
import { getWorkflowCategories, getAllWorkflows } from "../../api/workFlows";
import { createAgent } from "../../api/agent";
import {
  getAgentSetupForm,
  getClients,
  getWorkflow,
  setAgentSetupForm,
  setAllWorkflows,
  setSelectedClient,
  setWorkflow,
} from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const MultiStepForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [agentCategory, setAgentCategory] = useState([]);
  const [step, setStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isDeploying, setIsDeploying] = useState(false);
  const selectedWorkflow = useSelector(getWorkflow);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allClients = useSelector(getClients);

  // Gettign step from the selected workflow

  const agentSetupForm = useSelector(getAgentSetupForm);
  const numberOfSteps =
    selectedWorkflow?.number_of_steps_to_setup_agent === undefined
      ? 4
      : selectedWorkflow?.number_of_steps_to_setup_agent - 1;

  // Submit form

  const handleSubmit = () => {
    const obj = {
      ...agentSetupForm,
      predefined_models_id: selectedWorkflow?.predefined_models_id,
      workflow_id: selectedWorkflow?.id,
      agent_status: "inActive",
    };
    createAgent(obj)
      .then((res) => {
        if (res?.status === 200) {
          // Deselect Workflow
          dispatch(setWorkflow(null));
          dispatch(
            setAgentSetupForm({
              name: "",
              description: "",
              agent_system_prompt: "",
              agent_status: "",
              knowledge_base_integration_config_ids: "",
              action_and_tools_config_ids: "",
              tool_slack_constraints_json: "",
              tool_mail_constraints_json: "",
              deployed_phone_number: "",
              deployed_email: "",
              predefined_models_id: null,
              workflow_id: null,
              client_id: null,
            })
          );
          navigate("/");
        } else {
          // toast.error("Agent already created with same workflow");
          toast.error(res?.response?.data?.error_message);
        }
      })
      .catch((err) => console.log(err));
    dispatch(
      setAgentSetupForm({
        name: "",
        description: "",
        agent_system_prompt: "",
        agent_status: "",
        knowledge_base_integration_config_ids: "",
        action_and_tools_config_ids: "",
        tool_slack_constraints_json: "",
        tool_mail_constraints_json: "",
        deployed_phone_number: "",
        deployed_email: "",
        predefined_models_id: null,
        workflow_id: null,
        client_id: null,
      })
    );
  };

  // set values in the state

  const handleChange = (name, valueOrEvent) => {
    const value =
      valueOrEvent && valueOrEvent.target
        ? valueOrEvent.target.value
        : valueOrEvent;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    dispatch(
      setAgentSetupForm({
        ...agentSetupForm,
        [name]: value,
      })
    );
  };

  const handleChangeKB = (input) => (value) => {
    setFormData((prevData) => ({
      ...prevData,
      [input]: value,
    }));
  };

  // Get categories and the workflows

  useEffect(() => {
    getWorkflowCategories()
      .then((res) => {
        setAgentCategory(res);
      })
      .catch((err) => console.log(err));

    getAllWorkflows()
      .then((res) => {
        dispatch(setAllWorkflows(res));
      })
      .catch((err) => console.log(err));
  }, []);

  const confirmDeployment = async (deploymentType, deploymentConnection) => {
    return new Promise((resolve) => {
      setFormData((prevData) => ({
        ...prevData,
        deploymentType: deploymentType,
        deploymentConnection: deploymentConnection,
      }));

      setIsDeploying(true);
      setTimeout(() => {
        setIsDeploying(false);
        resolve();
      }, 5000);
    });
  };

  // Next Step

  const handleNext = () => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
    setStep(step + 1);
  };

  // Previous step

  const handlePrev = () => {
    if (completedSteps.includes(step)) {
      setCompletedSteps(completedSteps.filter((s) => s !== step));
    }

    setStep(step - 1);
  };
  const setIdAndClient = (client) => {
    dispatch(setSelectedClient(JSON.parse(client)));
    handleChange("client_id", JSON.parse(client).id);
  };
  useEffect(() => {
    dispatch(
      setAgentSetupForm({
        name: "",
        description: "",
        agent_system_prompt: "",
        agent_status: "",
        knowledge_base_integration_config_ids: "",
        action_and_tools_config_ids: "",
        tool_slack_constraints_json: "",
        tool_mail_constraints_json: "",
        deployed_phone_number: "",
        deployed_email: "",
        predefined_models_id: 0,
        workflow_id: null,
        client_id: null,
      })
    );
  }, []);

  return (
    <div className="flex flex-col items-center w-full p-4 min-h-screen customScroll">
      <div className="w-full flex justify-start items-start flex-col">
        <label htmlFor="select_client" className="mb-4">
          Select Client
        </label>
        <select
          name="select_client"
          onChange={(e) => setIdAndClient(e.target.value)}
          className="w-full max-w-xs rounded-md p-3 bg-gray-500"
        >
          <option value="" selected disabled>
            Select client...
          </option>
          {allClients?.map((client, index) => (
            <option value={JSON.stringify(client)} key={index}>
              {client?.company_name}
            </option>
          ))}
        </select>
      </div>

      {/* Progress Bar */}
      <div className="w-[60%] mt-8 mb-8">
        <div className="flex justify-between items-center mb-2">
          {[
            ...Array(
              selectedWorkflow?.number_of_steps_to_setup_agent === undefined
                ? 5
                : selectedWorkflow?.number_of_steps_to_setup_agent
            ),
          ].map((_, index) => (
            <React.Fragment key={index}>
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center text-[#101010] ${
                  step > index + 1 || completedSteps.includes(index + 1)
                    ? "bg-[#007AFF]"
                    : "bg-white"
                }`}
              >
                {completedSteps.includes(index + 1) ? (
                  <FaCheck color="white" />
                ) : (
                  index + 1
                )}
              </div>
              {index < numberOfSteps && (
                <div
                  className={`flex-1 h-1 ${
                    step > index + 1 || completedSteps.includes(index + 1)
                      ? "bg-[#007AFF]"
                      : "bg-white"
                  }`}
                ></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Form Steps */}
      {step === 1 && agentSetupForm.client_id !== null ? (
        <Step1 handleNext={handleNext} handleChange={handleChange} />
      ) : step === 1 ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <h2 className="font-extrabold text-2xl text-gray-400">
            Select client first!
          </h2>
        </div>
      ) : null}

      {step === 2 && (
        <Step2
          nextStep={handleNext}
          prevStep={handlePrev}
          handleChange={handleChange}
          agentCategory={agentCategory}
        />
      )}
      {step === 3 && (
        <Step3
          nextStep={handleNext}
          prevStep={handlePrev}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
        />
      )}
      {step === 4 && (
        <Step4
          nextStep={handleNext}
          prevStep={handlePrev}
          handleChange={handleChangeKB}
        />
      )}
      {/* {step === 5 && (
        <Step5
          nextStep={handleNext}
          prevStep={handlePrev}
          handleChange={handleChangeKB}
          values={formData}
        />
      )}
      {step === 6 && (
        <Step6
          confirmDeployment={confirmDeployment}
          prevStep={handlePrev}
          handleChange={handleChangeKB}
          values={formData}
          handleSubmit={handleSubmit}
          isDeploying={isDeploying}
        />
      )} */}

      {selectedWorkflow?.number_of_steps_to_setup_agent === 6 ? (
        <>
          {step === 5 && (
            <Step5
              nextStep={handleNext}
              prevStep={handlePrev}
              handleChange={handleChangeKB}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {step === 6 && (
            <Step6
              confirmDeployment={confirmDeployment}
              prevStep={handlePrev}
              handleChange={handleChangeKB}
              formData={formData}
              setFormData={setFormData}
              handleSubmit={handleSubmit}
              isDeploying={isDeploying}
            />
          )}
        </>
      ) : (
        step === 5 && (
          <Step6
            confirmDeployment={confirmDeployment}
            prevStep={handlePrev}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            isDeploying={isDeploying}
          />
        )
      )}
    </div>
  );
};
