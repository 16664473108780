import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createClient } from "../api/clients";

export const CreateClient = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    company_name: "",
    confirmPassword: "",
    role: "",
    company_size: 0,
    company_website: "",
    timezone: "",
    reference: "",
    primary_usecase: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmVisibility = () => {
    setIsConfirmVisible(!isConfirmVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if password and confirmPassword match
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    // Clear error if passwords match
    setError("");
    formData["role"] = "user";
    createClient(formData)
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("userDetails", JSON.stringify(res?.data));
          navigate("/");
        } else {
          toast.error(res?.response?.data?.error_message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className=" w-full">
      <div className="w-full  text-white flex flex-col items-center justify-center p-12">
        <div className="w-full  text-white flex items-center justify-center p-12">
          <div className="w-full max-w-md">
            <div className="text-xl font-semibold mb-6">
              Client Registeration
            </div>
            <form onSubmit={handleSubmit}>
              {/* password */}
              <div className="mb-4 w-full">
                <label htmlFor="name">Enter Client Name</label>
                <input
                  type={"text"}
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter password"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="mb-4 w-full">
                <label htmlFor="email">Email</label>
                <input
                  type={"email"}
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter password"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <label htmlFor="password">Enter your password</label>
              <div className="mb-4 relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Enter password"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                  minLength={8}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? (
                    <FaEyeSlash color="#4D4D4D" size={16} />
                  ) : (
                    <FaEye color="#4D4D4D" size={16} />
                  )}
                </div>
              </div>
              {/* Confirm */}
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
              <label htmlFor="confirmPassword">Confirm your password</label>
              <div className="mb-4 relative">
                <input
                  type={isConfirmVisible ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirm password"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                  minLength={8}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={toggleConfirmVisibility}
                >
                  {isConfirmVisible ? (
                    <FaEyeSlash color="#4D4D4D" size={16} />
                  ) : (
                    <FaEye color="#4D4D4D" size={16} />
                  )}
                </div>
              </div>
              {/* company */}
              <label htmlFor="company_name">Enter your company name</label>
              <div className="mb-4">
                <input
                  type="text"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleInputChange}
                  placeholder="Your company name"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                  required
                />
              </div>
              {/* company size */}
              <label htmlFor="company_size">Enter your company size</label>
              <div className="mb-4">
                <input
                  type="text"
                  name="company_size"
                  value={formData.company_size}
                  onChange={handleInputChange}
                  placeholder="Your company size"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                  required
                />
              </div>
              {/* company website */}
              <label htmlFor="company_website">Company website name</label>
              <div className="mb-4">
                <input
                  type="text"
                  name="company_website"
                  value={formData.company_website}
                  onChange={handleInputChange}
                  placeholder="Your company website"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                  required
                />
              </div>
              {/* time zone */}
              <div className="mb-4">
                <label htmlFor="timezone">Where did you find us?</label>
                <input
                  type="text"
                  name="timezone"
                  value={formData.timezone}
                  onChange={handleInputChange}
                  placeholder="Your time zone?"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                />
              </div>
              {/* about us */}
              <div className="mb-4">
                <label htmlFor="reference">Where did you find us?</label>
                <input
                  type="text"
                  name="reference"
                  value={formData.reference}
                  onChange={handleInputChange}
                  placeholder="Where did you hear about us? (optional)"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                />
              </div>
              {/* use case */}
              <div className="mb-4">
                <label htmlFor="primary_usecase">Primary use case</label>
                <input
                  type="text"
                  name="primary_usecase"
                  value={formData.primary_usecase}
                  onChange={handleInputChange}
                  placeholder="What is your primary use case?"
                  className="bg-transparent w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                />
              </div>

              {/* Sign */}
              <div className="mb-4">
                <button
                  type="submit"
                  className="w-full border border-gray-400 text-blacek px-6 py-2 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="">
        <p className="text-[#616161] test-sm font-normal text-center">
          © Normal Logistics 2024
        </p>
      </div>
    </div>
  );
};
