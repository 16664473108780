import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import clientReducer from "./clientSlice";
import userReducer from "./userSlice";
import workflowReducer from "./workflowsSlice";
import agentReducer from "./agentSlice";
import auditLogReducer from "./auditLogSlice";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    client: clientReducer,
    user: userReducer,
    agent: agentReducer,
    workflow: workflowReducer,
    logs: auditLogReducer,
  },
});
